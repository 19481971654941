<template>
  <product title="米动健康 VIP 卡"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    is-switch-detail
    is-switch-price
    :buy-link="link"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'vip-card',
  components: {
    Product
  },
  data () {
    return {
      slogan: '',
      colors: [
        '银卡',
        '金卡',
        '白金卡'
      ],
      price: [
        '299',
        '599',
        '3999'
      ],
      link: [
        'https://www.xiaomiyoupin.com/detail?gid=110025',
        'https://www.xiaomiyoupin.com/detail?gid=110417',
        'https://www.xiaomiyoupin.com/detail?gid=110420'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/vip-card/swiper/1.png'
        ],
        [
          CDN_URL + '/images/product/vip-card/swiper/2.png'
        ],
        [
          CDN_URL + '/images/product/vip-card/swiper/3.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        [
          CDN_URL + '/images/product/vip-card/detail/silver/01.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/02.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/03.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/04.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/05.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/06.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/07.jpg',
          CDN_URL + '/images/product/vip-card/detail/silver/08.jpg'
        ],
        [
          CDN_URL + '/images/product/vip-card/detail/gold/01.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/02.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/03.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/04.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/05.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/06.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/07.jpg',
          CDN_URL + '/images/product/vip-card/detail/gold/08.jpg'
        ],
        [
          CDN_URL + '/images/product/vip-card/detail/platina/01.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/02.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/03.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/04.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/05.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/06.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/07.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/08.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/09.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/10.jpg',
          CDN_URL + '/images/product/vip-card/detail/platina/11.jpg'
        ]
      ],
      paramImages: [
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
